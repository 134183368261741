import React, { useState, useEffect } from 'react'
//import QRCode from 'react-qr-code'
import { API, TOKEN } from '../../utils/env'
import './VarianteCode.scss'

import timediff from 'timediff'

import visual from '../../statics/img/Visual.jpg'
import logo from '../../statics/img/Logo.svg'
import abgelaufen from '../../statics/img/zeit.svg'
import gewonnen from '../../statics/img/gewonnen.svg'
import verloren from '../../statics/img/verloren.svg'
import achtung from '../../statics/img/achtung.svg'

const VarianteCode = (props) => {
  const code = props.code
  const version = props.version

  const [iconState, setIconState] = useState('achtung')
  const [allowed2Play, setAllowed2Play] = useState(false)
  const [bereitsGespielt, setBereitsGespielt] = useState(false)
  // 1. Zu erst muss die Version und der Code vorhanden sein. Sonst -> Fehler

  const timePast = localStorage.getItem('time')
  let pastHour = 0
  if (timePast !== null) {
    pastHour = timediff(timePast, new Date(), 'H')
    //console.log(pastHour)
    if (pastHour.hours >= 1) {
      localStorage.clear()
    }
  }

  const Icon = (props) => {
    const set = props.set
    let data

    switch (set) {
      case 'abgelaufen':
        data = abgelaufen
        break
      case 'gewonnen':
        data = gewonnen
        break
      case 'verloren':
        data = verloren
        break
      case 'achtung':
        data = achtung
        break

      default:
        data = achtung
        break
    }

    return (
      <span className="icon">
        <img src={data} alt="icon" />
      </span>
    )
  }

  const ErrorMsg = () => {
    return (
      <>
        <div className="visual">
          <img src={logo} className="logo" alt="TapConnect Logo" />
          <img src={visual} alt="" className="visual-bg" />
          <Icon set={iconState} />
        </div>
        <div className="ergebnis">
          <div className="message">
            <p>
              Leider ist ein
              <br />
              <span className="big slideUp">
                Fehler
                <br />
                aufgetreten.
              </span>
            </p>
            <p className="fadeIn">
              Versuche es bitte
              <br /> noch einmal...
            </p>
          </div>
        </div>
      </>
    )
  }

  if (code && version) {
    const [state, setState] = useState({
      gewinner: 0,
      teilnehmer: 0,
      runde: 0,
      ready: false,
      error: false,
    })
    // const [reload, setReload] = useState(false)

    // 2. Brauchen wir die Spieldaten, damit wir alle Dinge berechnen können:
    useEffect(() => {
      const abortController = new AbortController()
      const getData = async () => {
        try {
          const result = await fetch(API + '/api/singletons/get/gewinn1', {
            method: 'post',
            headers: {
              'Content-Type': 'application/json',
              'Cockpit-Token': TOKEN,
            },
            signal: abortController.signal,
          })
          const data = await result.json()

          setState((prev) => ({
            ...prev,
            gewinner: data.gewinner,
            teilnehmer: data.teilnehmer,
            runde: data.round,
            ready: true,
          }))
          return data
        } catch (error) {
          if (abortController.signal.aborted) {
            // cancelled
          } else throw error
        }
      }

      getData()
      return () => abortController.abort()
    }, [])

    // 3. Wenn die Spieldaten im state sind, versuchen wir herauszufinden ob der Spieler berechtigt ist. Dazu wird zu erst der Code gecheckt

    //Check if code exists in Database

    useEffect(() => {
      const abortController = new AbortController()

      // const markAsUSed = (id) => {
      //   fetch(API + '/api/collections/save/gewinn1Codes', {
      //     method: 'post',
      //     headers: {
      //       'Content-Type': 'application/json',
      //       'Cockpit-Token': TOKEN,
      //     },
      //     body: JSON.stringify({
      //       data: {
      //         _id: id,
      //         code: code,
      //         used: true,
      //         round: parseInt(state.runde),
      //       },
      //     }),
      //   }).then((res) => res.json())
      // }

      const codeValid = async (winCode) => {
        try {
          const result = await fetch(
            API + '/api/collections/get/gewinn1Codes',
            {
              method: 'post',
              headers: {
                'Content-Type': 'application/json',
                'Cockpit-Token': TOKEN,
              },
              body: JSON.stringify({
                filter: { code: winCode },
              }),
              signal: abortController.signal,
            },
          )
          const data = await result.json()

          const checkCode = data.entries[0]?.code ? data.entries[0].code : false
          //const codeID = data.entries[0]?._id

          console.log(checkCode)

          if (checkCode === winCode) {
            // if (data.entries[0]?.round >= state.runde) {
            //   setBereitsGespielt(true)
            //   setAllowed2Play(false)
            // } else {
            //   setAllowed2Play(true)
            //   markAsUSed(codeID)
            // }

            return true
          } else {
            setState((prev) => ({
              ...prev,
              error: true,
            }))
            return undefined
          }
        } catch (error) {
          if (abortController.signal.aborted) {
            // cancelled
          } else throw error
        }
      }

      if (state.ready && parseInt(state.runde) > 0) {
        const localCode = localStorage.getItem('localCode')
        const localRunde = localStorage.getItem('runde')

        if (localCode != code) {
          codeValid(code)
          localStorage.clear()
          setAllowed2Play(true)
        } else if (!localRunde || localRunde < state.runde) {
          codeValid(code)
          localStorage.clear()
          setAllowed2Play(true)
        } else {
          setBereitsGespielt(true)
        }
      }
    }, [code, state.ready, state.runde])

    const getRandomInt = (max) => {
      return Math.floor(Math.random() * max)
    }

    const saveToDatabase = (id, win) => {
      let timestamp = new Date().toISOString()

      fetch(API + '/api/collections/save/gewinn1Db', {
        method: 'post',
        headers: { 'Content-Type': 'application/json', 'Cockpit-Token': TOKEN },
        body: JSON.stringify({
          data: {
            id: code,
            time: timestamp,
            win: win,
          },
        }),
      }).then((res) => res.json())
    }

    const Ausgabe = () => {
      let ergebnis = localStorage.getItem('ergebnis')

      if (ergebnis === null) {
        if (state?.ready) {
          if (getRandomInt(state?.teilnehmer) <= state?.gewinner) {
            localStorage.setItem('ergebnis', 'gewonnen')
            setIconState('gewonnen')
            localStorage.setItem('localCode', code)
            localStorage.setItem('runde', state.runde)
            localStorage.setItem('time', new Date())
            saveToDatabase(code, 1)
            return (
              <div className="message">
                <h1 id="ergebnis">
                  Herzlichen
                  <br />
                  Glückwunsch!
                </h1>
                <p className="slideUp">
                  Sie haben <br /> <span className="big">gewonnen!</span>
                </p>
                <p className="fadeIn">
                  Holen Sie Ihren Gewinn
                  <br />
                  einfach bei der
                  <strong>Bartenbach AG</strong>,<br /> Standnummer 2 ab!
                </p>
              </div>
            )
          } else {
            localStorage.setItem('ergebnis', 'verloren')
            setIconState('verloren')
            localStorage.setItem('localCode', code)
            localStorage.setItem('runde', state.runde)
            localStorage.setItem('time', new Date())
            saveToDatabase(code, 0)
            return (
              <div className="message">
                <span>Sorry, diesmal leider</span>
                <h1 id="ergebnis">kein Gewinn ...</h1>
                <p>
                  Versuchen Sie es in der
                  <strong> nächsten Stunde </strong>
                  noch einmal.
                </p>
                <p className="fadeIn">
                  Oder kommen Sie doch einfach bei der Bartenbach AG,
                  Standnummer 2 vorbei. Da gibt’s noch mehr
                  <strong> Tap Connect! </strong>
                </p>
              </div>
            )
          }
        } else {
          return <span className="spinner"></span>
        }
      } else {
        if (ergebnis === 'gewonnen') {
          // let uid = localStorage.getItem('uid')
          setIconState('gewonnen')
          return (
            <div className="message">
              <h1 id="ergebnis">
                Herzlichen
                <br />
                Glückwunsch!
              </h1>
              <p className="slideUp">
                Sie haben <br /> <span className="big">gewonnen!</span>
              </p>
              <p className="fadeIn">
                Holen Sie Ihren Gewinn
                <br />
                einfach bei der
                <strong> Bartenbach AG</strong>,<br />
                Standnummer 2 ab!
              </p>
            </div>
          )
        } else {
          setIconState('verloren')
          return (
            <div className="message">
              <span>Sorry, diesmal leider</span>
              <h1 id="ergebnis">kein Gewinn ...</h1>
              <p>
                Versuchen Sie es in der
                <strong> nächsten Stunde </strong>
                noch einmal.
              </p>
              <p className="fadeIn">
                Oder kommen Sie doch einfach bei der
                <strong> Bartenbach AG</strong>, Standnummer 2 vorbei. Da gibt’s
                noch mehr <strong> Tap Connect! </strong>
              </p>
            </div>
          )
        }
      }
    }

    const Pending = () => {
      if (state.runde == 0) {
        setIconState('abgelaufen')
        return (
          <div className="message">
            <p>
              Einen kleinen
              <br />
              <span className="big slideUp">Moment noch...</span>
            </p>
            <p className="fadeIn">
              Das Spiel hat noch
              <br />
              nicht begonnen.
            </p>
          </div>
        )
      } else {
        return ''
      }
    }

    const Ergebnis = () => {
      const ergebnis = localStorage.getItem('ergebnis')

      if (ergebnis === 'gewonnen') {
        setIconState('abgelaufen')
        return (
          <span>
            ein <b>Gewinn</b>
          </span>
        )
      } else {
        setIconState('abgelaufen')
        return (
          <span>
            eine <b>Niete</b>
          </span>
        )
      }
    }
    let ergebnis = localStorage.getItem('ergebnis')
    if (state.error) {
      return <ErrorMsg />
    } else {
      return (
        <>
          <div className="visual">
            <img src={logo} className="logo" alt="Bartenbach Sports Logo" />
            <img src={visual} alt="" className="visual-bg" />
            <Icon set={iconState} />
          </div>
          <div className="ergebnis">
            <Pending />
            {allowed2Play && <Ausgabe />}
            {bereitsGespielt && (
              <div className="message">
                <p className="abgelaufen">
                  Deine Teilnahme ist <br />
                  <span className="big">abgelaufen</span>
                </p>
                <p>
                  Deine letzte Teilnahme
                  <br /> war <Ergebnis />
                </p>
                {ergebnis == 'verloren' && (
                  <p>
                    Kein Problem – nächste Stunde gibt’s wieder neue Chancen!
                    Und solange kommen Sie einfach zur{' '}
                    <strong>Bartenbach AG</strong>, Standnummer 2.
                  </p>
                )}
                {ergebnis == 'gewonnen' && (
                  <p>
                    Noch nicht abgeholt? <br />
                    Dann kommen Sie doch einfach zur{' '}
                    <strong>Bartenbach AG</strong>, Standnummer 2. Da gibt’s
                    noch mehr <strong>Tap Connect</strong>!
                  </p>
                )}
              </div>
            )}
          </div>
        </>
      )
    }
  } else {
    return <ErrorMsg />
  }
}

export default VarianteCode
