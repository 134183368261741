import React from 'react'
import { VARIANTE } from './utils/env'
import VarianteCode from './components/VarianteCode/VarianteCode'
import './App.scss'

function App() {
  const pathArray = window.location.pathname.split('/')
  const version = pathArray[1]
  const code = pathArray[2]
  return (
    <div className="App">
      {VARIANTE === 'code' ? (
        <VarianteCode version={version} code={code} />
      ) : (
        ''
      )}
    </div>
  )
}

export default App
